import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <path
      d="M18 6 6 18M6 6l12 12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgCloseIcon);
export default Memo;
